<template>
  <div
    class="article-survey"
  >
    <modular-tile
      :headerText="article.title"
      :headerLevel="1"
    >
      <div
        v-if="isTypeformAvailable"
        ref="typeform"
        class="article-survey__typeform"
      />
      <div
        v-else-if="isTypeformAvailabilityKnown"
        class="article-survey__unavailable"
      >
        <span>{{ i18n('item-unavailable') }}</span>
      </div>
      <a
        v-if="isTypeformAvailable"
        class="quiz_link"
        :href="`${url}&article_id=${nextArticleId || $route.params.id}&return_ch=true`">
        View an accessible version
      </a>
      <journey-navigation
        v-if="isJourney"
        :key="isLoading"
        :articleId="$route.params.id"
        v-bind="{
          canProceed,
          isLoading,
        }"
      />
      <reading-room-navigation
        v-else-if="isReadingRoom"
        :ariaLabel="$t('Content')"
        :categoryId="$route.params.categoryId"
        :id="$route.params.id"
        v-bind="{
          isLoading,
        }"
      />
    </modular-tile>
  </div>
</template>

<script>
  import { makeWidget } from '@typeform/embed'
  import { mapActions, mapGetters } from 'vuex'

  import { JourneyNavigation } from '@/components/journey'
  import { ReadingRoomNavigation } from '@/components/reading-room'
  import ModularTile from '@/components/tiles/modular-tile/ModularTile'

  import i18n from '@/mixins/i18n'

  export default {
    components: {
      JourneyNavigation,
      ModularTile,
      ReadingRoomNavigation,
    },

    mixins: [
      i18n,
    ],

    props: {
      article: {
        type: Object,
        required: true,
      },
      content: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      isCompleted: {
        type: Boolean,
        default: false,
      },
      isJourney: {
        type: Boolean,
        default: false,
      },
      isReadingRoom: {
        type: Boolean,
        default: false,
      },
    },

    mounted() {
      if (this.contentArticle && this.contentArticle.typeform_id) {
        this.checkTypeform()
      }
      const offset = this.getArticleOffset(this.$route.params.id, 1)
      const section = this.getSectionOfArticle(this.$route.params.id, 1)
      if (!offset) {
        const offsetSection = this.getSectionOffset(section.id, 1)
        if (offsetSection) {
          const [item] = offsetSection.items
          this.nextArticleId = item.id
        }
      } else {
        this.nextArticleId = offset.id
      }
    },

    watch: {
      isTypeformAvailable(val) {
        this.isTypeformAvailabilityKnown = true

        if (val) {
          this.$nextTick(this.initTypeform)
        }
      },
      contentArticle: {
        handler() {
          if (this.contentArticle) {
            this.checkTypeform()
          }
        },
      },
    },

    data() {
      return {
        isSubmitted: false,
        isTypeformAvailabilityKnown: false,
        isTypeformAvailable: null,
        nextArticleId: 0,
      }
    },

    methods: {
      ...mapActions('article', [
        'checkTypeformAvailability',
        'checkTypeformSavedAnswers',
      ]),
      initTypeform() {
        makeWidget(
          this.$refs.typeform,
          this.url,
          {
            hideFooter: true,
            hideHeaders: true,
            opacity: 0,
            onSubmit: this.onSubmit,
          },
        )
      },
      onSubmit() {
        this.checkTypeformSavedAnswers(this.contentArticle.typeform_id)
          .then(() => this.$emit('submit'))
      },
      checkTypeform() {
        this.checkTypeformAvailability({
          typeformId: this.contentArticle.typeform_id,
          systemSectionName: this.getCurrentSection.system_name,
        })
          .then(isAvailable => this.isTypeformAvailable = isAvailable)
          .catch(() => this.isTypeformAvailable = false)
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('program', [
        'getCurrentSection',
      ]),
      ...mapGetters('program', [
        'getArticleOffset',
        'getSectionOfArticle',
        'getSectionOffset',
        'isTrailingOpd',
      ]),
      isLoading() {
        const loadings = [
          'article/checkTypeformAvailability',
          'article/checkTypeformSavedAnswers',
          'article/fetchArticle',
          'readingRoom/fetchItem',
        ]

        const progressLoadings = [
        ]

        return this.getLoadingStatesForActions(
          this.isSubmitted
            ? loadings
            : [
              ...loadings,
              ...progressLoadings,
            ],
        )
      },
      canProceed() {
        return !this.isLoading
          && (!this.isTypeformAvailable
            || this.isReadingRoom
            || !this.isRequired
            || this.isSubmitted
            || this.isCompleted
          )
      },
      isRequired() {
        return this.article.track_progress
      },
      url() {
        return `${this.contentArticle.url}&ch_section_name=${this.sectionName}`
      },
      sectionName() {
        return this.getCurrentSection.system_name
      },
      contentArticle() {
        return this.content || this.article
      },
    },

    slug: 'component.base.base-article',
  }
</script>

<style lang="scss">
  .article-survey {
    .quiz_link {
      margin-top: 2rem;
      text-decoration: underline;
      width: fit-content;
    }
    &__typeform {
      height: 500px;
      margin: 4rem 0 0;
    }
    .modular-tile {
      flex-grow: 0;
      margin: 0;
      &__header {
        margin-bottom: 0;
      }
      &__header-row {
        margin: 0.4rem 0 0.8rem;
        @include min-lg {
          margin: 1rem 0 0.6rem;
        }
      }
      &__content {
        flex-direction: column;
      }
      &__main {
        @include min-lg {
          padding: 0 3rem 3rem;
        }
      }
    }
  }
</style>
